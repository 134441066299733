<template lang="pug">
  v-container
    v-row(justify="center")
      v-col(cols="12" lg="5" md="6")
        v-card
          v-container
            table-temperature-monitoring
</template>
<script>
export default {
  name: 'TemperatureMonitoring',
  components: {
    tableTemperatureMonitoring: () => import('./Table'),
  },
}
</script>